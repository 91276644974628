import React, { Component } from "react";
import {
  Tabs,
  Badge,
  Select,
  Table,
  Skeleton,
  Tag,
  Avatar,
  message,
  Modal,
  Dropdown,
  Menu,
  Popconfirm,
  Button,
  Drawer,
  notification,
  Tooltip,
  Popover,
  Pagination,
  Input,
  Spin,
} from "antd";
import _ from "lodash";
import styled from "styled-components";
import PushToStore from "./PushToStore";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { apolloClient } from "../../apollo-client";
import CarouSelImage from "./CarouselImage";
import ImageUrl from "../ImageUrl";
import moment from "moment";
import {
  ExclamationCircleOutlined,
  MoreOutlined,
  EyeOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import orders from "../../graphql/queries/orders/orders";
import assignCarrier from "../../graphql/mutates/assignCarrier";
import { AWS_CLOUDFRONT_URL } from "../../config";
import UploadDesign from "./UploadDesign";
import CarrierSelect from "../carriers/CarrierSelect";
import defaultImage from "../../assets/images/default.png";
import FilterOrder, { orderInternalStatus } from "./FilterOrder";
import exportOrders from "../../graphql/mutates/exportOrders";
import CustomerInfo from "../externalOrders/CustomerInfo";
import ChangeFulfillService from "./ChangeFulfillService";
import OrderActivity from "./OrderActivity";
import FulfillVariant from "./FulfillVariant";
import { valueFromAST } from "graphql";
import CreateTask from "./CreateTask";
import ChangeOrderVariant from "./ChangeOrderVariant";
import ChangeShippingMethod from "./ChangeShippingMethod";

const { confirm } = Modal;
const { TabPane } = Tabs;

const Container = styled.div`
  .order-action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .search-reset {
    display: flex;
    width: -webkit-fill-available;
  }
  .bulk-action {
    margin-left: 20px;
    min-width: 150px;
  }
  @media only screen and (max-width: 768px) {
    .order-action {
      flex-direction: column-reverse;
    }
    .ant-select {
      width: 48%;
    }
  }
  @media only screen and (max-width: 1420px) {
    .bulk-action {
      margin-bottom: 10px;
    }
    .order-action {
      flex-direction: column-reverse;
    }
  }
  .no-border {
    .ant-btn-link {
      border: none;
      padding: 0;
    }
    .icon-button {
      padding-left: 15px;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      border: none;
    }
  }
`;

class Orders extends Component {
  state = {
    filter: {
      limit: 25,
      offset: 0,
      siteId: null,
      status: null,
      search: null,
      productId: null,
      from: null,
      to: null,
      userId: null,
      external: false,
      fulfilmentId: null,
      isPersonalized: false,
      shippingMethod: null,
      pushStatus: null,
      baseId: null,
      type: "name",
    },
    setCarrier: null,
    page: 1,
    loading: false,
    orderSelect: [],
    visible: false,
    typeOfFile: null,
    aggs: [],
    action: [],
    // showModalBU: false,
    showUpload: false,
    showModalSelect: false,
    templateByFulfillment: [],
    hideText: true,
    showCustomerInfo: false,
    valueCancell: "",
    selectedOrder: null,
    showModalUpdateVariant: false,
    showModalUpdateSM: false,
    exportingOrders: false,
  };

  showImageModal = (order, typeOfFile) => {
    this.setState({
      visible: {
        ...order,
      },
      typeOfFile,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      action: [],
      showModalSelect: false,
      setCarrier: null,
      showUpload: false,
    });
  };

  onChangeStatus = (value) => {
    const { orderSelect } = this.state;
    const orderSelectId = orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!) {
            updateOrderStatus(orderIds: $orderIds, status: $status)
          }
        `,
        variables: {
          orderIds: orderSelectId,
          status: value,
        },
      })
      .then(() => {
        message.success("Order has been " + value);
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onCancelOrder = (id, text) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!, $comment: String) {
            updateOrderStatus(
              orderIds: $orderIds
              status: $status
              comment: $comment
            )
          }
        `,
        variables: {
          orderIds: [id],
          status: "cancelled",
          comment: text,
        },
      })
      .then(() => {
        message.success("Order has been cancel");
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs, valueCancell: "" });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onConvertPersonalized = (orderId) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderId: ID!) {
            setPersonalizedOrder(orderId: $orderId)
          }
        `,
        variables: {
          orderId,
        },
      })
      .then(() => {
        message.success("Order has been moved to personalized");
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  pushToThirdParty = (ids, name) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($ids: [ID!]) {
            pushToThirdParty(ids: $ids)
          }
        `,
        variables: {
          ids: [ids],
        },
      })
      .then(() => {
        message.success(`Order ready submit to ${name}`);
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  pushToStoreThirdParty = () => {
    const { orderSelect } = this.state;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($ids: [ID!]) {
            pushToThirdParty(ids: $ids)
          }
        `,
        variables: {
          ids: orderIdSelect,
        },
      })
      .then(() => {
        message.success(
          "Pushing product in the background, the order status will be updated in a moment.",
          5
        );
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  reSubmitOrder = (id) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderId: [ID!]) {
            reSubmitFulfillmentOrder(orderId: $orderId)
          }
        `,
        variables: {
          orderId: [id],
        },
      })
      .then(() => {
        message.success("Order re submit order success");
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  pushToStoreReSubmit = () => {
    const { orderSelect } = this.state;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderId: [ID!]) {
            reSubmitFulfillmentOrder(orderId: $orderId)
          }
        `,
        variables: {
          orderId: orderIdSelect,
        },
      })
      .then(() => {
        message.success(
          "Pushing product in the background, the order status will be updated in a moment.",
          5
        );
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  reTrackSubmit = () => {
    const { orderSelect } = this.state;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($ids: [ID!]) {
            reTrackFulfillmentOrder(ids: $ids)
          }
        `,
        variables: {
          ids: orderIdSelect,
        },
      })
      .then(() => {
        message.success(
          "Re track in the background, the order status will be updated in a moment.",
          5
        );
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  pushToStore = () => {
    const { orderSelect } = this.state;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    // const cookieExpired = orderSelect
    //   .reduce(
    //     (init, item) =>
    //       init.map((el) => el.site.id).includes(item.site.id)
    //         ? init
    //         : [...init, item],
    //     []
    //   )
    //   .filter(
    //     (order) =>
    //       moment(order.site.cookieUpdatedAt)
    //         .add(1, "days")
    //         .diff(moment(), "minutes") < 10
    //   );
    // if (cookieExpired.length > 0) {
    //   cookieExpired.forEach((order) => {
    //     if (
    //       moment(cookieExpired[0].site.cookieUpdatedAt)
    //         .add(1, "days")
    //         .diff(moment(), "minutes") < 0
    //     ) {
    //       this.setState({ action: [] });
    //       notification.error({
    //         message: cookieExpired[0].site.title + "'s cookie has expired!",
    //       });
    //     } else if (
    //       0 <
    //       moment(cookieExpired[0].site.cookieUpdatedAt)
    //         .add(1, "days")
    //         .diff(moment(), "minutes") <
    //       10
    //     ) {
    //       this.setState({ action: [] });
    //       notification.warning({
    //         message:
    //           cookieExpired[0].site.title +
    //           "'s cookie will expire in" +
    //           moment(cookieExpired[0].site.cookieUpdatedAt)
    //             .add(1, "days")
    //             .diff(moment(), "minutes") +
    //           "minutes",
    //       });
    //     }
    //   });
    // } else {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($order_ids: [ID!]) {
            pushProduct(order_ids: $order_ids)
          }
        `,
        variables: {
          order_ids: orderIdSelect,
        },
      })
      .then(() => {
        message.success(
          "Pushing product in the background, the order status will be updated in a moment.",
          5
        );
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
    // }
  };

  showConfirm = (value) => {
    confirm({
      title:
        "Do you want to " +
        (value === "designed"
          ? "approve"
          : value === "push-product" || value === "push-third"
            ? "push to store"
            : value === "re-submit"
              ? "reSubmit"
              : value === "re-track"
                ? "re track"
                : value) +
        " these order?",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        if (value === "push-product") {
          this.pushToStore();
        } else if (value === "push-third") {
          this.pushToStoreThirdParty();
        } else if (value === "re-submit") {
          this.pushToStoreReSubmit();
        } else if (value === "re-track") {
          this.reTrackSubmit();
        } else {
          this.onChangeStatus(value);
        }
      },
      onCancel: () => {
        this.setState({ action: [] });
      },
    });
  };

  // showModal = (value) => {
  //   this.setState({
  //     showModalBU: value,
  //   });
  // };

  // handleOk = () => {
  //   this.setState({
  //     showModalBU: false,
  //   });
  // };

  actionBulkUpload(value) {
    this.setState({ action: value });
    switch (value) {
      // case "change-designer":
      // case "create-task":
      //   return this.showModal(value);
      case "push-product":
      case "cancelled":
      case "designed":
      case "rejected":
      case "push-third":
      case "re-submit":
      case "re-track":
        // case "remove print files":
        return this.showConfirm(value);
      default:
        break;
    }
  }

  handleChangeCarrier = () => {
    const { showModalSelect, setCarrier } = this.state;
    apolloClient
      .mutate({
        mutation: assignCarrier,
        variables: {
          orderIds: [showModalSelect.id],
          carrierId: setCarrier,
        },
      })
      .then(() => {
        notification.success({
          message: "Select carrier successful!",
        });
        this.handleCancel();
        this.refetch();
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  handleExportOrder = (templateId) => {
    this.setState({ exportingOrders: true });
    const { orderSelect, filter } = this.state;
    const variableFilter = orderSelect.length
      ? {
        orderIds: orderSelect.map((order) => order.id),
        fulfilmentId: filter.fulfilmentId,
      }
      : {
        siteId: filter.siteId,
        fulfilmentId: filter.fulfilmentId,
        status: filter.status,
        from: filter.from ? moment(filter.from).startOf("day") : null,
        to: filter.to ? moment(filter.to).endOf("day") : null,
      };
    apolloClient
      .mutate({
        mutation: exportOrders,
        variables: {
          templateId: templateId,
          filter: variableFilter,
        },
      })
      .then((res) => {
        notification.success({
          message: "Export orders successful!",
        });
        this.setState({ exportingOrders: false });
        window.location.href = res.data.exportOrders;
        this.setState({
          orderSelect: [],
        });
        this.handleCancel();
        this.refetch().then((newData) => {
          this.setState({
            aggs: newData.data.orders.aggs,
          });
        });
      })
      .catch((err) => {
        this.setState({ exportingOrders: false });
        notification.error(err.message);
      });
  };

  render() {
    const {
      filter,
      page,
      visible,
      typeOfFile,
      aggs,
      orderSelect,
      action,
      // showModalBU,
      showUpload,
      showModalSelect,
      setCarrier,
      templateByFulfillment,
      hideText,
      showCustomerInfo,
      showActivity,
      selectedOrder,
      showModalUpdateVariant,
      showModalUpdateSM,
      exportingOrders,
    } = this.state;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    // console.log(params.get("limit"));

    const content = (data) => {
      const newData = data ? data.fulfillService : templateByFulfillment;
      return (
        <Menu>
          <Menu.Item
            style={{
              padding: 0,
              margin: 0,
              lineHeight: "initial",
              height: 25,
            }}
          >
            <Popconfirm
              placement="bottom"
              title={
                <span>
                  Are you sure to export the order(s)?
                  {templateByFulfillment?.type === "csv" && (
                    <span>
                      <br />
                      After exporting, the order(s) will be fulfilled.
                    </span>
                  )}
                </span>
              }
              onConfirm={() => {
                this.setState(
                  {
                    orderSelect: data ? [data] : orderSelect,
                  },
                  () => this.handleExportOrder(null)
                );
              }}
              onCancel
              okText="Yes"
              cancelText="No"
            >
              Default template
            </Popconfirm>
          </Menu.Item>
          {newData?.templates?.map((template) => (
            <Menu.Item
              style={{
                padding: 0,
                margin: 0,
                lineHeight: "initial",
                height: 25,
              }}
              key={template.id}
            >
              <Popconfirm
                placement="bottom"
                title={
                  <span>
                    Are you sure to export the order(s)?
                    <br />
                    After exporting, the order(s) will be fulfilled.
                  </span>
                }
                onConfirm={() => {
                  this.setState(
                    {
                      orderSelect: data ? [data] : orderSelect,
                    },
                    () => this.handleExportOrder(template.id)
                  );
                }}
                onCancel
                okText="Yes"
                cancelText="No"
              >
                {template.name}
              </Popconfirm>
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    const disableButton =
      !filter.fulfilmentId ||
      (filter.fulfilmentId &&
        templateByFulfillment.type === "fuel" &&
        filter.status !== "designed" &&
        filter.status !== "fulfilled" &&
        filter.status !== "in_production") ||
      (filter.fulfilmentId &&
        templateByFulfillment.type === "csv" &&
        filter.status !== "designed");
    const { currentUser } = this.props;
    const isAdmin =
      currentUser && currentUser.roles.find((role) => role === "Administrator");
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ orderSelect: selectedRows });
      },
    };
    let value;
    let isSame = true;
    let isFulfill = null;
    let isStatus;
    orderSelect.forEach((_, index) => {
      if (index === 0) {
        value = orderSelect[index];
        isStatus = orderSelect[index].status;
        isFulfill = orderSelect[index].fulfillService.type;
      } else {
        if (orderSelect[index].status !== value.status) {
          isSame = false;
          isStatus = null;
        }
        if (
          value.fulfillService.type !== orderSelect[index].fulfillService.type
        ) {
          isFulfill = null;
        }
        return isFulfill;
      }
      return { isSame, isStatus, isFulfill };
    });
    return (
      <Container>
        <Spin tip="Exporting orders. Please stay on this page until the process is complete." spinning={exportingOrders}>
          <div className="order-action">
            <FilterOrder
              onChangeFilter={(dataFilter) =>
                this.setState({
                  ...this.state,
                  filter: dataFilter.filter,
                  action: dataFilter.action ?? action,
                  page: dataFilter.page ?? page,
                  orderSelect: dataFilter.orderSelect ?? orderSelect,
                })
              }
              setTemplateByFulfillment={(data) =>
                this.setState({
                  templateByFulfillment: data,
                })
              }
              isAdmin={isAdmin}
              filter={filter}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Select
                className="bulk-action"
                disabled={
                  !(orderSelect.length > 0 && isSame) ||
                    orderSelect.find((order) =>
                      ["Pending", "Pushing", "Pushed"].includes(order.push_status)
                    )
                    ? true
                    : filter.fulfilmentId === null &&
                      filter.status === "in_production"
                      ? true
                      : false
                }
                placeholder="Bulk action"
                onSelect={(value) => this.actionBulkUpload(value)}
                value={action}
              >
                {
                  {
                    // unassigned: (
                    //   <>
                    //     <Select.Option value="create-task">
                    //       Create Task
                    //     </Select.Option>
                    //     <Select.Option value="cancelled">Cancel</Select.Option>
                    //   </>
                    // ),
                    // rejected: (
                    //   <Select.Option value="change-designer">
                    //     Change Designer
                    //   </Select.Option>
                    // ),
                    // processing: (
                    //   <Select.Option value="change-designer">
                    //     Change Designer
                    //   </Select.Option>
                    // ),
                    // pending_approve: (
                    //   <>
                    //     <Select.Option value="change-designer">
                    //       Change Designer
                    //     </Select.Option>
                    //     <Select.Option value="designed">Approve</Select.Option>
                    //     <Select.Option value="rejected">Reject</Select.Option>
                    //   </>
                    // ),
                    designed: (
                      <>
                        {orderSelect.length && isFulfill === "fuel" && (
                          <Select.Option
                            disabled={
                              orderSelect.find((order) =>
                                ["Pending", "Pushing", "Pushed"].includes(
                                  order.push_status
                                )
                              )
                                ? true
                                : false
                            }
                            value="push-product"
                          >
                            Push to store
                          </Select.Option>
                        )}
                        {orderSelect.length &&
                          orderSelect.filter((item) => {
                            if (
                              (item.fulfillService?.type === "dreamship" ||
                                item.fulfillService?.type === "customcat") &&
                              item.push_status === null
                            ) {
                              return item;
                            }
                          }).length === orderSelect.length && (
                            <Select.Option
                              disabled={
                                orderSelect.find((order) =>
                                  ["Pending", "Pushing", "Pushed"].includes(
                                    order.push_status
                                  )
                                )
                                  ? true
                                  : false
                              }
                              value="push-third"
                            >
                              Push to store
                            </Select.Option>
                          )}
                        {/* <Select.Option value="change-designer">
                        Change Designer
                      </Select.Option> */}
                        <Select.Option value="cancelled">Cancel</Select.Option>
                      </>
                    ),
                    error: (
                      <>
                        {isAdmin && orderSelect.length && isFulfill === "fuel" && (
                          <Select.Option
                            disabled={
                              orderSelect.find((order) =>
                                ["Pending", "Pushing", "Pushed"].includes(
                                  order.push_status
                                )
                              )
                                ? true
                                : false
                            }
                            value="push-product"
                          >
                            Push to store
                          </Select.Option>
                        )}
                        {orderSelect.length &&
                          orderSelect.filter((item) => {
                            if (
                              item.fulfillService?.type === "dreamship" ||
                              item.fulfillService?.type === "customcat"
                            ) {
                              return item;
                            }
                          }).length === orderSelect.length && (
                            <Select.Option
                              // disabled={
                              //   orderSelect.find((order) =>
                              //     ["Pending", "Pushing", "Pushed"].includes(
                              //       order.push_status
                              //     )
                              //   )
                              //     ? true
                              //     : false
                              // }
                              value="re-submit"
                            >
                              Re submit
                            </Select.Option>
                          )}
                      </>
                    ),
                    in_production: (
                      <>
                        {orderSelect.length &&
                          orderSelect.filter((item) => {
                            if (
                              item.fulfillService?.type === "dreamship" ||
                              item.fulfillService?.type === "customcat" ||
                              item.fulfillService?.type === "fuel"
                            ) {
                              return item;
                            }
                          }).length === orderSelect.length && (
                            <Select.Option value="re-track">
                              Re Track
                            </Select.Option>
                          )}
                      </>
                    ),
                  }[isStatus]
                }
              </Select>
            </div>
          </div>
          <Tabs
            activeKey={filter.status ? filter.status : "all"}
            onChange={(key) =>
              this.setState({
                filter: {
                  ...filter,
                  status: key === "all" ? null : key,
                  offset: 0,
                },
                action: [],
                orderSelect: [],
                page: 1,
              })
            }
            tabBarExtraContent={
              <Tooltip
                title={
                  disableButton
                    ? "You have to select a fulfillment and Designed status!"
                    : null
                }
                color="#880000"
              >
                {!disableButton ? (
                  <Popover
                    title="Select template:"
                    trigger="click"
                    content={content}
                    placement="bottomRight"
                  >
                    <Button disabled={disableButton} type="primary">
                      Export Orders
                    </Button>
                  </Popover>
                ) : (
                  <Button disabled={disableButton} type="primary">
                    Export Orders
                  </Button>
                )}
              </Tooltip>
            }
          >
            <TabPane
              tab={
                <Badge
                  overflowCount={9999999}
                  count={_.sum(aggs && aggs.map((agg) => agg.count))}
                  style={{
                    background: "#fff",
                    color: "#000",
                    border: "1px solid #f5f5f5",
                    right: -20,
                  }}
                >
                  All
                </Badge>
              }
              key="all"
            />
            {orderInternalStatus.map((s) => (
              <Tabs.TabPane
                key={s.key}
                tab={
                  <Badge
                    overflowCount={9999999}
                    count={_.sum(
                      aggs &&
                      aggs
                        .filter((agg) => agg.status === s.key)
                        .map((a) => a.count)
                    )}
                    style={{ backgroundColor: s.color }}
                  >
                    {s.title}
                  </Badge>
                }
              />
            ))}
          </Tabs>
          <Query
            query={orders}
            fetchPolicy="no-cache"
            variables={{
              filter: {
                ...filter,
                limit: params.get("limit") ? params.get("limit") : filter.limit,
                from: filter.from ? moment(filter.from).startOf("day") : null,
                to: filter.to ? moment(filter.to).endOf("day") : null,
              }
            }}
            onCompleted={(res) => this.setState({ aggs: res.orders.aggs })}
          >
            {({ error, loading, data, refetch }) => {
              if (loading) return <Skeleton />;
              if (error) return <div>{error.toString()}</div>;
              this.refetch = refetch;
              const orders = data.orders.hits;
              const total = data.orders.count;
              const columns = [
                {
                  title: "Product",
                  dataIndex: "product",
                  key: "product",
                  width: 350,
                  render: (product, order) => (
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        gridTemplateColumns: "70px auto",
                      }}
                    >
                      <Avatar
                        shape="square"
                        style={{
                          width: 60,
                          height: 60,
                          lineHeight: 60,
                          marginRight: 10,
                          cursor: "pointer",
                        }}
                        src={
                          product.mockups[0]
                            ? product.mockups[0].file.source === "store"
                              ? product.mockups[0].file.url
                              : `${AWS_CLOUDFRONT_URL}/${product.mockups[0].file.key}`
                            : defaultImage
                        }
                        onClick={() => {
                          if (product.mockups[0]) {
                            this.showImageModal(order, "product");
                          }
                        }}
                      />
                      <div className="no-border">
                        {`${product.title}`}
                        <a
                          className="icon-button"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            product.sites.find(
                              (site) => site.siteId === order.siteId
                            )?.permalink
                          }
                        >
                          <EyeOutlined />
                        </a>
                        <div>
                          {order.variant !== null ? (
                            <div style={{ position: "relative" }}>
                              {order.variant.attributes.map((item, key) => (
                                <div key={key}>
                                  <em style={{ fontSize: 13 }}>
                                    <b>{item.name}: </b>
                                    {item.option}
                                  </em>
                                </div>
                              ))}
                              {["processing", "unassigned", "designed", "pending_approve"].includes(order.status) && <EditOutlined style={{ position: "absolute", top: 5, right: 0, color: "#1890ff" }} onClick={() => this.setState({ selectedOrder: order, showModalUpdateVariant: true })} />}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  title: "TYPE",
                  key: "type",
                  width: 150,
                  render: (order) => (
                    <span>
                      {order.variant.productBase.type
                        ? order.variant.productBase.type.origin
                        : order.variant.productBase.title}
                    </span>
                  ),
                },
                {
                  title: "ID/SKU",
                  key: "id/sku",
                  width: 300,
                  render: (order) => (
                    <div style={{ display: "grid" }}>
                      <span>App ID: {order.id}</span>
                      <span>
                        {order.site.title}: {order.name} ({order.originId})
                      </span>
                      <span style={{ color: "#777777" }}>
                        SKU: {order.variant.sku.replace(/\s/g, "-")}
                      </span>
                    </div>
                  ),
                },

                {
                  title: "Customize",
                  key: "customize",
                  width: 350,
                  render: (order, record) => {
                    const printFileShow = order.product?.designs?.find(
                      (design) => design.fileId
                    );
                    return (
                      <div
                        style={{
                          display: "grid",
                          alignItems: "center",
                          gridTemplateColumns:
                            order.product?.designs?.length > 0
                              ? "70px 248px"
                              : "318px",
                        }}
                      >
                        {order.product?.designs?.length > 0 ? (
                          <div>
                            <Button
                              onClick={() =>
                                this.setState({ showUpload: record })
                              }
                              className="ant-tag-orange
                            "
                              icon={<CheckOutlined />}
                            >
                              View
                            </Button>
                            {/* <div
                            style={{
                              width: 60,
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.showImageModal(order, "printFile")
                            }
                          >
                            <ImageUrl
                              widthImage="60px"
                              heightImage="60px"
                              imageKey={
                                printFileShow?.file?.thumbnail ??
                                printFileShow?.file?.key
                              }
                            />
                          </div> */}
                          </div>
                        ) : null}
                        <div>
                          {/* {order.customize
                          ? order.customize.map((option, index) => (
                              <div key={index}>
                                <em>{option.name}: </em>
                                <div>
                                  {option.value.length > 50 ? (
                                    <div>
                                      {option.value.substring(0, 50)}
                                      <span
                                        style={{
                                          display:
                                            hideText === order.id
                                              ? "none"
                                              : "initial",
                                        }}
                                      >
                                        ...
                                      </span>
                                      <span
                                        style={{
                                          display:
                                            hideText === order.id
                                              ? "initial"
                                              : "none",
                                        }}
                                      >
                                        {option.value.substring(50)}
                                      </span>
                                      {index === order.customize.length - 1 && (
                                        <div>
                                          <Button
                                            size="small"
                                            type="link"
                                            onClick={() => {
                                              this.setState({
                                                hideText: order.id,
                                              });
                                            }}
                                            style={{
                                              display:
                                                hideText === order.id
                                                  ? "none"
                                                  : "initial",
                                              padding: 0,
                                            }}
                                          >
                                            Show more...
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              this.setState({
                                                hideText: false,
                                              });
                                            }}
                                            size="small"
                                            type="link"
                                            style={{
                                              display:
                                                hideText === order.id
                                                  ? "initial"
                                                  : "none",
                                              padding: 0,
                                            }}
                                          >
                                            Show less
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <span
                                      style={{ whiteSpace: "pre-line" }}
                                      dangerouslySetInnerHTML={{
                                        __html: option.value,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            ))
                          : null} */}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title: "Note",
                  key: "note",
                  width: 200,
                  render: (order) => (
                    <div>
                      {order.note ? (
                        <div>
                          <span>{order.note}</span>
                        </div>
                      ) : null}
                    </div>
                  ),
                },
                {
                  title: "Qty - Cost",
                  key: "qty-cost",
                  width: 120,
                  render: (order) => (
                    <div>
                      {order.quantity} x ${order.price}
                      <br />
                      <em style={{ fontSize: 12 }}>
                        {moment(order.createdAt).format("MMM DD, YYYY")}
                      </em>
                    </div>
                  ),
                },
                {
                  title: "Carrier",
                  key: "carrier",
                  width: 120,
                  render: (order) => (
                    <div className="no-border">
                      {order.carrier ? (
                        <div>
                          <span>{order.carrier.name}</span>
                          {order.status !== "fulfilled" ? (
                            <span>
                              <Button
                                onClick={() =>
                                  this.setState({
                                    showModalSelect: order,
                                  })
                                }
                                type="link"
                                className="icon-button"
                              >
                                <EditOutlined />
                              </Button>
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <Button
                          onClick={() =>
                            this.setState({
                              showModalSelect: order,
                            })
                          }
                          type="link"
                        >
                          Select Carrier
                        </Button>
                      )}
                    </div>
                  ),
                },
                {
                  title: "Fullfillment",
                  key: "fulfillService",
                  width: 200,
                  render: (order) => (
                    <div>
                      {order.fulfillService ? (
                        <div>{order.fulfillService.name}</div>
                      ) : null}
                      {order.shippingMethod ? (
                        <div>{order.shippingMethod} {["processing", "unassigned", "designed", "pending_approve", "error"].includes(order.status) && <EditOutlined style={{ color: "#1890ff", marginLeft: 10 }} onClick={() => this.setState({ selectedOrder: order, showModalUpdateSM: true })} />}</div>
                      ) : null}
                    </div>
                  ),
                },
                {
                  title: "Designer",
                  dataIndex: "task",
                  key: "designer",
                  width: 150,
                  render: (task) => (
                    <div>
                      {task && task.user !== null ? (
                        <Tag key={task.user?.id} color="blue">
                          {task.user?.firstName + " " + task.user?.lastName}
                        </Tag>
                      ) : null}
                    </div>
                  ),
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  width: 150,
                  render: (status, record) => (
                    <span
                      style={{
                        color: ["processing", " "].includes(status)
                          ? "#f94"
                          : status === "fulfilled"
                            ? "green"
                            : // : status === "unassigned"
                            // ? "unset"
                            ["cancelled", "rejected"].includes(status)
                              ? "#ff5500"
                              : status === "error"
                                ? "red"
                                : "blue",
                      }}
                    >
                      {record?.push_log && status.toLowerCase() === "error" ? (
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordWrap: "break-word",
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                            >
                              {record?.push_log}
                            </div>
                          }
                          title={false}
                          trigger="click"
                        >
                          <span style={{ cursor: "pointer" }}>Error</span>
                        </Popover>
                      ) : (
                        orderInternalStatus.find((item) => item.key?.toLowerCase() === status?.toLowerCase())?.title
                      )}
                    </span>
                  ),
                },
                {
                  title: "Actions",
                  dataIndex: "id",
                  key: "actions",
                  align: "right",
                  fixed: "right",
                  width: 220,
                  render: (id, record) => {
                    switch (record.status) {
                      case "unassigned":
                        return (
                          <>
                            {/* {isAdmin && record.product?.designStatus === null && ( */}
                            {isAdmin && (
                              <CreateTask
                                orderId={id}
                                refetch={() => {
                                  refetch().then((newData) => {
                                    this.setState({
                                      aggs: newData.data.orders.aggs,
                                    });
                                  });
                                }}
                              />
                            )}
                          </>
                        );
                      case "rejected":
                        return (
                          <Dropdown.Button
                            icon={<MoreOutlined />}
                            type="link"
                            onClick={() => this.setState({ showUpload: record })}
                            overlay={
                              <Menu
                                style={{
                                  background: "white",
                                  padding: 10,
                                  lineHeight: "25px",
                                }}
                              >
                                <a
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showActivity: record });
                                  }}
                                >
                                  Order Activity
                                </a>
                                {isAdmin && (
                                  <ChangeFulfillService
                                    order={record}
                                    refetch={() => {
                                      refetch().then((newData) => {
                                        this.setState({
                                          aggs: newData.data.orders.aggs,
                                        });
                                      });
                                    }}
                                  />
                                )}
                                <div>
                                  <Popconfirm
                                    // title="Are you sure cancel this order？"
                                    title={
                                      <div>
                                        <p>
                                          {`Are you sure cancel this order id ${id}？`}
                                        </p>
                                        <span>Reason:</span>
                                        <Input
                                          value={this.state.valueCancell}
                                          onChange={(value) =>
                                            this.setState({
                                              valueCancell: value.target.value,
                                            })
                                          }
                                        ></Input>
                                      </div>
                                    }
                                    onConfirm={() =>
                                      this.onCancelOrder(
                                        id,
                                        this.state.valueCancell
                                      )
                                    }
                                    okText="Yes"
                                    icon={
                                      <QuestionCircleOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    okButtonProps={{ type: "danger" }}
                                    cancelText="No"
                                    placement="left"
                                  >
                                    <a href="/#">Cancel</a>
                                  </Popconfirm>
                                </div>
                              </Menu>
                            }
                          >
                            Upload
                          </Dropdown.Button>
                        );
                      case "processing":
                        return (
                          <Dropdown.Button
                            icon={<MoreOutlined />}
                            type="link"
                            onClick={() => this.setState({ showUpload: record })}
                            overlay={
                              <Menu
                                style={{
                                  background: "white",
                                  padding: 10,
                                  lineHeight: "25px",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    this.setState({ showCustomerInfo: record });
                                  }}
                                >
                                  <a
                                    onClick={(e) => e.preventDefault()}
                                    href="/#"
                                  >
                                    Customer info
                                  </a>
                                </div>
                                <a
                                  style={{ display: "block" }}
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showActivity: record });
                                  }}
                                >
                                  Order Activity
                                </a>
                                {isAdmin && (
                                  <>
                                    {/* <UpdateTask
                                    designerId={
                                      record.task && record.task.user.id
                                    }
                                    orderId={id}
                                    refetch={() => {
                                      refetch().then((newData) => {
                                        this.setState({
                                          aggs: newData.data.orders.aggs,
                                        });
                                      });
                                    }}
                                  /> */}
                                    <ChangeFulfillService
                                      order={record}
                                      refetch={() => {
                                        refetch().then((newData) => {
                                          this.setState({
                                            aggs: newData.data.orders.aggs,
                                          });
                                        });
                                      }}
                                    />
                                    {record.status === "processing" && (
                                      <>
                                        <Popconfirm
                                          title="Are you sure convert this order to personalized？"
                                          onConfirm={() =>
                                            this.onConvertPersonalized(id)
                                          }
                                          okText="Yes"
                                          cancelText="No"
                                          placement="topLeft"
                                        >
                                          <a href="/#">Convert to personalized</a>
                                        </Popconfirm>
                                        <br />
                                      </>
                                    )}
                                    <Popconfirm
                                      // title="Are you sure cancel this order？"
                                      title={
                                        <div>
                                          <p>
                                            {`Are you sure cancel this order id ${id}？`}
                                          </p>
                                          <span>Reason:</span>
                                          <Input
                                            value={this.state.valueCancell}
                                            onChange={(value) =>
                                              this.setState({
                                                valueCancell: value.target.value,
                                              })
                                            }
                                          ></Input>
                                        </div>
                                      }
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      okButtonProps={{ type: "danger" }}
                                      onConfirm={() =>
                                        this.onCancelOrder(
                                          id,
                                          this.state.valueCancell
                                        )
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                      placement="left"
                                    >
                                      <a href="/#">Cancel</a>
                                    </Popconfirm>
                                  </>
                                )}
                              </Menu>
                            }
                          >
                            {record.product.isCustomize
                              ? "Upload"
                              : !record.product.designStatus
                                ? "Upload"
                                : record.product.designStatus === "done"
                                  ? "Approve"
                                  : "View"}
                          </Dropdown.Button>
                        );
                      // case "pending_approve":
                      //   return (
                      //     <Dropdown.Button
                      //       icon={<MoreOutlined />}
                      //       type="link"
                      //       onClick={() => this.setState({ showUpload: record })}
                      //       overlay={
                      //         <Menu
                      //           style={{
                      //             background: "white",
                      //             padding: 10,
                      //             lineHeight: "25px",
                      //           }}
                      //         >
                      //           {isAdmin && (
                      //             <>
                      //               <UpdateTask
                      //                 designerId={
                      //                   record.task && record.task.user.id
                      //                 }
                      //                 orderId={id}
                      //                 refetch={() => {
                      //                   refetch().then((newData) => {
                      //                     this.setState({
                      //                       aggs: newData.data.orders.aggs,
                      //                     });
                      //                   });
                      //                 }}
                      //               />
                      //               <Popconfirm
                      //                 title="Are you sure cancel this order？"
                      //                 onConfirm={() => this.onCancelOrder(id)}
                      //                 okText="Yes"
                      //                 cancelText="No"
                      //                 placement="topLeft"
                      //               >
                      //                 <a href="/#">Cancel</a>
                      //               </Popconfirm>
                      //             </>
                      //           )}
                      //         </Menu>
                      //       }
                      //     >
                      //       Approve Design
                      //     </Dropdown.Button>
                      //   );
                      case "designed":
                        return (
                          <Dropdown.Button
                            disabled={["Pending", "Pushing"].includes(
                              record.push_status
                            )}
                            icon={<MoreOutlined />}
                            type="link"
                            overlay={
                              <Menu
                                style={{
                                  background: "white",
                                  padding: 10,
                                  lineHeight: "25px",
                                }}
                              >
                                {/* {isAdmin && record.push_status !== "Pushed" && (
                                <UpdateTask
                                  designerId={
                                    record.task && record.task.user.id
                                  }
                                  orderId={id}
                                  refetch={() => {
                                    refetch().then((newData) => {
                                      this.setState({
                                        aggs: newData.data.orders.aggs,
                                      });
                                    });
                                  }}
                                />
                              )} */}
                                {(!isAdmin ||
                                  record.fulfillService?.type !== "fuel") && (
                                    <div>
                                      <Popover
                                        title="Select template:"
                                        trigger="click"
                                        content={content(record)}
                                        placement="left"
                                      >
                                        <a
                                          onClick={(e) => e.preventDefault()}
                                          href="/#"
                                        >
                                          Export Order
                                        </a>
                                      </Popover>
                                    </div>
                                  )}

                                <div
                                  onClick={() => {
                                    this.setState({ showCustomerInfo: record });
                                  }}
                                >
                                  <a
                                    onClick={(e) => e.preventDefault()}
                                    href="/#"
                                  >
                                    Customer info
                                  </a>
                                </div>
                                {record.push_status === null && isAdmin ? (
                                  <ChangeFulfillService
                                    order={record}
                                    refetch={() => {
                                      refetch().then((newData) => {
                                        this.setState({
                                          aggs: newData.data.orders.aggs,
                                        });
                                      });
                                    }}
                                  />
                                ) : null}
                                <a
                                  style={{ display: "block" }}
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showUpload: record });
                                  }}
                                >
                                  View Designs
                                </a>
                                <a
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showActivity: record });
                                  }}
                                >
                                  Order Activity
                                </a>
                                {isAdmin && (
                                  <div>
                                    <Popconfirm
                                      title="Are you sure convert this order to personalized？"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      okButtonProps={{ type: "danger" }}
                                      onConfirm={() =>
                                        this.onConvertPersonalized(id)
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                      placement="topLeft"
                                    >
                                      <a href="/#">Convert to personalized</a>
                                    </Popconfirm>

                                    <br />
                                    <Popconfirm
                                      // title="Are you sure cancel this order？"
                                      title={
                                        <div>
                                          <p>
                                            {`Are you sure cancel this order id ${id}？`}
                                          </p>
                                          <span>Reason:</span>
                                          <Input
                                            value={this.state.valueCancell}
                                            onChange={(value) =>
                                              this.setState({
                                                valueCancell: value.target.value,
                                              })
                                            }
                                          ></Input>
                                        </div>
                                      }
                                      onConfirm={() =>
                                        this.onCancelOrder(
                                          id,
                                          this.state.valueCancell
                                        )
                                      }
                                      okText="Yes"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      okButtonProps={{ type: "danger" }}
                                      cancelText="No"
                                      placement="left"
                                    >
                                      <a href="/#">Cancel</a>
                                    </Popconfirm>
                                  </div>
                                )}
                              </Menu>
                            }
                          >
                            {isAdmin && record.fulfillService?.type === "fuel" ? (
                              <PushToStore
                                disabled={[
                                  "Pending",
                                  "Pushing",
                                  "Pushed",
                                ].includes(record.push_status)}
                                order={record}
                                refetch={() => {
                                  refetch().then((newData) => {
                                    this.setState({
                                      aggs: newData.data.orders.aggs,
                                    });
                                  });
                                }}
                              />
                            ) : isAdmin &&
                              (record.fulfillService?.type === "dreamship" ||
                                record.fulfillService?.type === "customcat") &&
                              record.push_status === null ? (
                              <Popconfirm
                                title="Are you sure push to store？"
                                onConfirm={() => {
                                  this.pushToThirdParty(
                                    id,
                                    record.fulfillService.name
                                  );
                                }}
                                okText="Yes"
                                okButtonProps={{ type: "danger" }}
                                cancelText="No"
                                placement="leftTop"
                              >
                                <a href="/#">Push To store</a>
                              </Popconfirm>
                            ) : (
                              <Popover
                                title="Select template:"
                                trigger="click"
                                content={content(record)}
                                placement="bottomRight"
                              >
                                Export Order
                              </Popover>
                            )}
                          </Dropdown.Button>
                        );
                      case "fulfilled":
                        return (
                          <Dropdown.Button
                            icon={<MoreOutlined />}
                            type="link"
                            onClick={() => this.setState({ showUpload: record })}
                            overlay={
                              <Menu
                                style={{
                                  background: "white",
                                  padding: 10,
                                  lineHeight: "25px",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    this.setState({ showCustomerInfo: record });
                                  }}
                                >
                                  <a
                                    onClick={(e) => e.preventDefault()}
                                    href="/#"
                                  >
                                    Customer info
                                  </a>
                                </div>
                                <a
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showActivity: record });
                                  }}
                                >
                                  Order Activity
                                </a>
                              </Menu>
                            }
                          >
                            View Designs
                          </Dropdown.Button>
                        );
                      case "in_production":
                        return (
                          <Dropdown.Button
                            icon={<MoreOutlined />}
                            type="link"
                            onClick={() => this.setState({ showUpload: record })}
                            overlay={
                              <Menu
                                style={{
                                  background: "white",
                                  padding: 10,
                                  lineHeight: "25px",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    this.setState({ showCustomerInfo: record });
                                  }}
                                >
                                  <a
                                    onClick={(e) => e.preventDefault()}
                                    href="/#"
                                  >
                                    Customer info
                                  </a>
                                </div>
                                <a
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showActivity: record });
                                  }}
                                >
                                  Order Activity
                                </a>
                                <div>
                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>
                                          {`Are you sure cancel this order id ${id}？`}
                                        </p>
                                        <span>Reason:</span>
                                        <Input
                                          value={this.state.valueCancell}
                                          onChange={(value) =>
                                            this.setState({
                                              valueCancell: value.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    }
                                    onConfirm={() =>
                                      this.onCancelOrder(
                                        id,
                                        this.state.valueCancell
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                    placement="left"
                                    icon={
                                      <QuestionCircleOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    okButtonProps={{ type: "danger" }}
                                  >
                                    <a style={{ display: "block" }} href="/#">
                                      Cancel
                                    </a>
                                  </Popconfirm>
                                </div>
                                {record.fulfillService?.type === "dreamship" ||
                                  record.fulfillService?.type === "customcat" ||
                                  record.fulfillService?.type === "fuel" ? (
                                  <div>
                                    <Popconfirm
                                      title={`Are you sure re track this order id ${id}？`}
                                      placement="left"
                                      onConfirm={() => {
                                        apolloClient
                                          .mutate({
                                            mutation: gql`
                                            mutation($ids: [ID!]) {
                                              reTrackFulfillmentOrder(ids: $ids)
                                            }
                                          `,
                                            variables: {
                                              ids: [id],
                                            },
                                          })
                                          .then(() => {
                                            message.success(
                                              "Re track in the background, the order status will be updated in a moment.",
                                              5
                                            );
                                            this.refetch().then((newData) => {
                                              this.setState({
                                                aggs: newData.data.orders.aggs,
                                              });
                                            });
                                          })
                                          .catch((err) => {
                                            message.error(err.message);
                                          });
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <a style={{ display: "block" }} href="/#">
                                        Re track
                                      </a>
                                    </Popconfirm>
                                  </div>
                                ) : null}
                              </Menu>
                            }
                          >
                            View Designs
                          </Dropdown.Button>
                        );
                      case "cancelled":
                        return (
                          <div>
                            <Dropdown.Button
                              icon={<MoreOutlined />}
                              type="link"
                              onClick={() =>
                                this.setState({ showUpload: record })
                              }
                              overlay={
                                <Menu
                                  style={{
                                    background: "white",
                                    padding: 10,
                                    lineHeight: "25px",
                                  }}
                                >
                                  <a
                                    href="/#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ showActivity: record });
                                    }}
                                  >
                                    Order Activity
                                  </a>
                                </Menu>
                              }
                            >
                              Upload
                            </Dropdown.Button>
                          </div>
                        );

                      case "error":
                        return (
                          <div>
                            <Dropdown.Button
                              icon={<MoreOutlined />}
                              type="link"
                              onClick={() =>
                                this.setState({ showUpload: record })
                              }
                              overlay={
                                <Menu
                                  style={{
                                    background: "white",
                                    padding: 10,
                                    lineHeight: "25px",
                                  }}
                                >
                                  {record.fulfillService?.type === "dreamship" ||
                                    record.fulfillService?.type === "customcat" ? (
                                    <div
                                      onClick={() =>
                                        this.reSubmitOrder(record.id)
                                      }
                                    >
                                      <a
                                        onClick={(e) => e.preventDefault()}
                                        href="/#"
                                      >
                                        ReSubmit
                                      </a>
                                    </div>
                                  ) : null}
                                  {record.fulfillService?.type === "dreamship" ||
                                    record.fulfillService?.type === "customcat" ? (
                                    <div
                                      onClick={() => {
                                        this.setState({
                                          showCustomerInfo: record,
                                        });
                                      }}
                                    >
                                      <a
                                        onClick={(e) => e.preventDefault()}
                                        href="/#"
                                      >
                                        Customer info
                                      </a>
                                    </div>
                                  ) : isAdmin &&
                                    record.fulfillService?.type === "fuel" ? (
                                    <PushToStore
                                      disabled={[
                                        "Pending",
                                        "Pushing",
                                        "Pushed",
                                      ].includes(record.push_status)}
                                      order={record}
                                      refetch={() => {
                                        refetch().then((newData) => {
                                          this.setState({
                                            aggs: newData.data.orders.aggs,
                                          });
                                        });
                                      }}
                                    />
                                  ) : null}
                                  <a
                                    href="/#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ showActivity: record });
                                    }}
                                    style={{ display: "block" }}
                                  >
                                    Order Activity
                                  </a>
                                  {isAdmin && (
                                    <ChangeFulfillService
                                      order={record}
                                      // title={"Change FF"}
                                      // titleTootip="Change fulfillment Service"
                                      refetch={() => {
                                        refetch().then((newData) => {
                                          this.setState({
                                            aggs: newData.data.orders.aggs,
                                          });
                                        });
                                      }}
                                    />
                                  )}
                                  <div>
                                    <Popconfirm
                                      title={
                                        <div>
                                          <p>
                                            {`Are you sure cancel this order id ${id}？`}
                                          </p>
                                          <span>Reason:</span>
                                          <Input
                                            value={this.state.valueCancell}
                                            onChange={(value) =>
                                              this.setState({
                                                valueCancell: value.target.value,
                                              })
                                            }
                                          ></Input>
                                        </div>
                                      }
                                      onConfirm={() =>
                                        this.onCancelOrder(
                                          id,
                                          this.state.valueCancell
                                        )
                                      }
                                      okText="Yes"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      okButtonProps={{ type: "danger" }}
                                      cancelText="No"
                                      placement="left"
                                    >
                                      <a href="/#">Cancel</a>
                                    </Popconfirm>
                                  </div>
                                </Menu>
                              }
                            >
                              Upload
                            </Dropdown.Button>
                          </div>
                        );
                      case "await":
                        return (
                          <div>
                            <Dropdown.Button
                              icon={<MoreOutlined />}
                              type="link"
                              overlay={
                                <Menu
                                  style={{
                                    background: "white",
                                    padding: 10,
                                    lineHeight: "25px",
                                  }}
                                >
                                  {record.variant.productBase?.autoRender &&
                                    ((!record.variant.ccId &&
                                      record.fulfillService?.type ===
                                      "customcat") ||
                                      (!record.variant.dsId &&
                                        record.fulfillService?.type ===
                                        "dreamship")) ? (
                                    <a
                                      href="/#"
                                      style={{ display: "block" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ showFFVariant: record });
                                      }}
                                    >
                                      Mapping FFS
                                    </a>
                                  ) : null}
                                  <a
                                    href="/#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ showActivity: record });
                                    }}
                                  >
                                    Order Activity
                                  </a>
                                  <div>
                                    <Popconfirm
                                      title={
                                        <div>
                                          <p>
                                            {`Are you sure cancel this order id ${id}？`}
                                          </p>
                                          <span>Reason:</span>
                                          <Input
                                            value={this.state.valueCancell}
                                            onChange={(value) =>
                                              this.setState({
                                                valueCancell: value.target.value,
                                              })
                                            }
                                          ></Input>
                                        </div>
                                      }
                                      onConfirm={() =>
                                        this.onCancelOrder(
                                          id,
                                          this.state.valueCancell
                                        )
                                      }
                                      okText="Yes"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      okButtonProps={{ type: "danger" }}
                                      cancelText="No"
                                      placement="left"
                                    >
                                      <a href="/#">Cancel</a>
                                    </Popconfirm>
                                  </div>
                                </Menu>
                              }
                            >
                              {isAdmin && (
                                <ChangeFulfillService
                                  order={record}
                                  title={"Change FF"}
                                  titleTootip="Change fulfillment Service"
                                  refetch={() => {
                                    refetch().then((newData) => {
                                      this.setState({
                                        aggs: newData.data.orders.aggs,
                                      });
                                    });
                                  }}
                                />
                              )}
                            </Dropdown.Button>
                          </div>
                        );
                      default:
                        return null;
                    }
                  },
                },
              ];
              filter.status === "designed" &&
                columns.splice(6, 0, {
                  title: "Push Status",
                  dataIndex: "push_status",
                  key: "push_status",
                  width: 130,
                  render: (push_status, { push_log }) => (
                    <span
                      style={{
                        color: ["pushed", "pushing"].includes(
                          push_status && push_status.toLowerCase()
                        )
                          ? "green"
                          : push_status && push_status.toLowerCase() === "pending"
                            ? "#f94"
                            : push_status && push_status.toLowerCase() === "error"
                              ? "red"
                              : "unset",
                      }}
                    >
                      {push_status?.toLowerCase() === "error" && push_log ? (
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordWrap: "break-word",
                                maxheight: 400,
                                overflow: "auto",
                              }}
                            >
                              {push_log}
                            </div>
                          }
                          title={false}
                          trigger="click"
                        >
                          <span style={{ cursor: "pointer" }}>Error</span>
                        </Popover>
                      ) : (
                        push_status &&
                        push_status.charAt(0).toUpperCase() + push_status.slice(1)
                      )}
                    </span>
                  ),
                });
              ["in_production", "fulfilled"].includes(filter.status) &&
                columns.splice(8, 0, {
                  title: "Tracking code",
                  width: 260,
                  dataIndex: "trackings",
                  render: (tracking) => (
                    <div>
                      {tracking.map((el) => (
                        <div key={el.id} style={{ marginBottom: 10 }}>
                          <a
                            style={{
                              padding: "5px 10px",
                              background: "#bbe5b3",
                              borderRadius: 2,
                            }}
                            href={`${el.fuelTrackUrl
                              ? el.fuelTrackUrl
                              : el.trackingUrl.replace(
                                "{{TRACKING_CODE}}",
                                el.code
                              )
                              }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {el.code}
                          </a>
                        </div>
                      ))}
                    </div>
                  ),
                });

              ["in_production", "fulfilled"].includes(filter.status) &&
                columns.splice(4, 0, {
                  title: "3rd Party ID",
                  key: "submitOrderId",
                  dataIndex: "submitOrderId",
                  width: 200,
                  render: (submitOrderId, record, index) => {
                    if (
                      ["dreamship", "customcat"].includes(
                        record.fulfillService?.type
                      )
                    ) {
                      return (
                        <div>
                          <p>Order ID: {submitOrderId}</p>
                          <p>
                            Product ID:{" "}
                            {record.variant?.productBase?.autoRender
                              ? record.fulfillService.type === "dreamship"
                                ? record.variant.dsId
                                : record.variant.ccId
                              : record.variant.productBaseVariant &&
                                record.fulfillService.type === "dreamship"
                                ? record.variant.productBaseVariant.dsId
                                : record.variant.productBaseVariant.ccId}
                          </p>
                        </div>
                      );
                    }
                  },
                });
              filter.status === "cancelled" &&
                columns.splice(9, 0, {
                  title: "reason",
                  dataIndex: "comment",
                  key: "comment",
                  width: 150,
                  render: (comment) => <div>{comment}</div>,
                });
              const tableWidth = _.sum(columns.map((c) => c.width));
              return (
                <div>
                  <Pagination
                    style={{
                      padding: "12px 16px 12px 16px",
                      borderBottom: "1px solid rgb(223, 227, 232)",
                      width: "100%",
                    }}
                    pageSize={params.get("limit") ? params.get("limit") : filter.limit}
                    total={total}
                    current={page}
                    showSizeChanger={false}
                    onChange={(page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...filter,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    }}
                  />
                  <Table
                    scroll={{ x: tableWidth }}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={orders}
                    pagination={{
                      pageSize: params.get("limit") ? params.get("limit") : filter.limit,
                      total: total,
                      current: page,
                      showSizeChanger: false,
                      onChange: (page, pageSize) => {
                        this.setState({
                          page: page,
                          filter: {
                            ...filter,
                            offset: (page - 1) * pageSize,
                          },
                        });
                      },
                    }}
                    rowKey={(record) => record.id}
                    loading={loading}
                  />
                  {/* <Modal
                  title={
                    showModalBU === "create-task"
                      ? "Create Task"
                      : "Change Designer"
                  }
                  visible={typeof showModalBU === "string" ? true : false}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  footer={null}
                >
                  <>
                    {showModalBU === "create-task" ? (
                      <CreateTaskForm
                        hide={this.handleCancel}
                        refetch={() => {
                          refetch().then((newData) => {
                            this.setState({ aggs: newData.data.orders.aggs });
                          });
                        }}
                        orderSelect={orderSelect}
                      />
                    ) : (
                      <UpdateTaskForm
                        orderSelect={orderSelect}
                        hide={this.handleCancel}
                        refetch={() => {
                          refetch().then((newData) => {
                            this.setState({ aggs: newData.data.orders.aggs });
                          });
                        }}
                      />
                    )}
                  </>
                </Modal> */}
                </div>
              );
            }}
          </Query>
          <Modal
            title={typeOfFile === "product" ? "Product Images" : "Print Files"}
            visible={typeof visible === "object" ? true : false}
            onCancel={this.handleCancel}
            key={visible.id && visible.id}
            footer={null}
            width={500}
          >
            <CarouSelImage
              images={
                typeOfFile === "product"
                  ? visible.product && visible.product.mockups
                  : visible.product?.designs
              }
            />
          </Modal>
          <Modal
            title="Select Carrier"
            visible={!!showModalSelect}
            onCancel={this.handleCancel}
            key={showModalSelect && showModalSelect.id}
            width={500}
            okText="Save"
            onOk={() => this.handleChangeCarrier()}
          >
            <CarrierSelect
              multiple={false}
              value={showModalSelect.carrierId ?? setCarrier}
              onChange={(value) =>
                this.setState({
                  setCarrier: value,
                })
              }
            />
          </Modal>
          <Modal
            title="Set fulfillment variant"
            visible={!!this.state.showFFVariant}
            onCancel={() => {
              this.setState({ showFFVariant: false });
            }}
            footer={[
              <Button
                onClick={() => {
                  this.setState({ showFFVariant: false });
                }}
              >
                Cancel
              </Button>,
              <Button form="myForm" key="submit" htmlType="submit" type="primary">
                Save
              </Button>,
            ]}
          >
            <FulfillVariant
              order={this.state.showFFVariant}
              refetch={() => {
                this.refetch().then((newData) => {
                  this.setState({
                    aggs: newData.data.orders.aggs,
                  });
                });
              }}
              closeModal={() => {
                this.setState({ showFFVariant: false });
              }}
            />
          </Modal>
          <Drawer
            visible={showUpload ? true : false}
            title={showUpload ? showUpload.product.title : ""}
            onClose={this.handleCancel}
            footer={null}
            placement="right"
            width={800}
          >
            <UploadDesign
              refetch={() => {
                this.refetch().then((newData) => {
                  this.setState({
                    aggs: newData.data.orders.aggs,
                  });
                });
              }}
              onClose={this.handleCancel}
              productOrder={showUpload}
            />
          </Drawer>
          {showCustomerInfo && <Drawer
            title="Customer Info"
            placement="right"
            width={500}
            onClose={() => {
              this.setState({ showCustomerInfo: false });
            }}
            visible={typeof showCustomerInfo === "object" ? true : false}
          >
            <CustomerInfo
              order={showCustomerInfo}
              onClose={() => {
                this.setState({ showCustomerInfo: false });
              }}
            />
          </Drawer>}
          <Drawer
            title="Activities"
            placement="right"
            width={500}
            onClose={() => {
              this.setState({ showActivity: false });
            }}
            visible={showActivity}
          >
            <OrderActivity order={showActivity} />
          </Drawer>
          {showModalUpdateVariant &&
            <ChangeOrderVariant order={selectedOrder} visible={showModalUpdateVariant} setVisible={() => this.setState({ selectedOrder: null, showModalUpdateVariant: false })} onUpdated={() => this.refetch()} />
          }
          {showModalUpdateSM &&
            <ChangeShippingMethod order={selectedOrder} visible={showModalUpdateSM} setVisible={() => this.setState({ selectedOrder: null, showModalUpdateSM: false })} onUpdated={() => {
              setTimeout(() => {
                this.refetch()
              }, 1000)
            }} />
          }
        </Spin>
      </Container>
    );
  }
}

export default Orders;
